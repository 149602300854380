<template>
  <mds-layout-grid class="select-app__container">
    <mds-loader
      v-if="isLoading"
      class="intersector__status--loading"
      size="small"
    />
    <mds-row
      v-if="!isLoading"
      align-horizontal="center"
    >
      <mds-col
        v-for="tenant in filteredTenants"
        :key="tenant.code"
        :cols="6"
        class="select-app__card-container"
      >
        <mds-card
          class="select-app__card"
          :title="`Web Crawler for ${tenant.name}`"
          :caption="`Launch Web Crawler for ${tenant.name}`"
          @click.native="selectTenantAndRedirect(tenant.code, routes.PROVIDERS)"
        >
          <template v-slot:mds-card-supplemental-content>
            <img
              :src="require('@/assets/illustrations/WebCrawlerCompaines.svg')"
              alt="ReferenceData"
            >
          </template>
        </mds-card>
      </mds-col>
      <mds-col
        v-if="canViewExchangeMonitors"
        :cols="6"
        class="select-app__card-container"
      >
        <mds-card
          class="select-app__card"
          title="Web Crawler for Exchange Monitors"
          caption="Launch Web Crawler for Exchange Monitors"
          @click.native="selectTenantAndRedirect('EXCHANGE_MONITORS', routes.PROVIDERS_EM)"
        >
          <template v-slot:mds-card-supplemental-content>
            <img
              :src="require('@/assets/illustrations/WebCrawlerEM.svg')"
              alt="ReferenceData"
            >
          </template>
        </mds-card>
      </mds-col>
    </mds-row>
  </mds-layout-grid>
</template>

<script>
import MdsCard from '@mds/card';
import MdsLoader from '@mds/loader';
import ROUTES from '@/constants/routes.constant';
import { MODULE, COMMON } from '@/constants/store.constant';
import { mapGetters, mapActions } from 'vuex';
import { getTenants } from '@/services/api/tenant.service';
import AUTH from '@/constants/auth.constant';

export default {
  name: 'SelectAppPage',
  layout: 'Empty',
  components: {
    MdsCard,
    MdsLoader,
  },
  data () {
    return {
      routes: ROUTES,
      systemTenants: [],
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      userTenants: `${MODULE.AUTH}/${COMMON.GET_STATE}`,
    }),
    filteredTenants () {
      const userTenantCodes = this.userTenants('tenants').map((t) => t.code);
      return this.systemTenants.filter(
        (tenant) => tenant.code !== 'EXCHANGE_MONITORS' && tenant.code !== 'ADMIN' && userTenantCodes.includes(tenant.code)
      );
    },
    canViewExchangeMonitors () {
      return this.userTenants('tenants').some(
        (tenant) => tenant.code === 'EXCHANGE_MONITORS'
      );
    },
  },
  async created () {
    this.isLoading = true;
    await this.fetchSystemTenants();
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      setCurrentTenant: `${MODULE.AUTH}/${AUTH.SET_CURRENT_TENANT}`,
    }),
    async fetchSystemTenants () {
      const { data: tenants } = await getTenants();
      this.systemTenants = tenants;
    },
    selectTenantAndRedirect (tenantCode, route) {
      if (tenantCode !== 'EXCHANGE_MONITORS') {
        this.setCurrentTenant(tenantCode);
      } else {
        this.setCurrentTenant(null);
      }
      this.redirect(route);
    },
    redirect (name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.select-app {
  &__container {
    margin: 0 auto;
    margin-top: 80px;
    width: 800px;
  }

  &__card {
    cursor: pointer;
    margin: 10px;

    &-container {
      display: flex;
      justify-content: center;
    }

    &--disabled {
      background-color: $mds-color-neutral-80;
      pointer-events: none;
    }
  }
}
</style>
